import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Nav from "./components/Nav";
import Intro from "./components/Intro";
import Language from "./components/Language";
import Credits from "./components/Credits";
import Theme from "./components/Theme";
import Projects from "./components/Projects";
import About from "./components/About";
import Welcome from "./components/Welcome";
import { projects } from "./content/projects";
import { useLocation } from "react-router-dom";

function App() {
  const [language, setLanguage] = useState("EN");

  const location = useLocation();

  const sendFormData = async (location) => {
    const formData = new FormData();
    formData.append(
      "subject",
      `Someone connected to your ${location.pathname} page`
    );
    formData.append("access_key", "e7e127ca-a7c4-4ac3-af0e-822e6dd1172f");
    formData.append("name", "admin");
    formData.append("email", "x@x@gmail.com");
    formData.append(
      "message",
      `Someone connected to your ${location.pathname} page`
    );
    formData.append("redirect", "https://web3forms.com/success");

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData,
      });
      if (response.ok) {
      } else {
      }
    } catch (error) {}
  };

  useEffect(() => {
    const locationInfo = {
      pathname: location.pathname,
      search: location.search,
      hash: location.hash,
    };
    sendFormData(locationInfo);
  }, [location]);

  useEffect(() => {
    preloadImages();
  }, []);

  const preloadImages = () => {
    projects.forEach((project) => {
      const img = new Image();
      img.src = process.env.PUBLIC_URL + project.img;
      const imgPhone = new Image();
      imgPhone.src = process.env.PUBLIC_URL + project.imgPhone;
    });
  };

  return (
    <div>
      <div className="App ">
        <Language onLanguageChange={setLanguage} language={language} />
        <div id="frame" className="text-sm font-thin select-none">
          <Nav />
          <Routes>
            <Route path="/" element={<Intro language={language} />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/about" element={<About language={language} />} />
          </Routes>
        </div>
        <Theme />
        <Credits />
      </div>
      <Welcome />
    </div>
  );
}

export default App;
